<template>
  <div class="Polaris-FormLayout__Item">
    <div class="">
      <div class="Polaris-Labelled__LabelWrapper">
        <div class="Polaris-Label"><label :id="id + 'label'" :for="id + 'input'" class="Polaris-Label__Text"
            v-text="label"> </label></div>
      </div>
      <div class="Polaris-Connected">
        <div class="Polaris-Connected__Item Polaris-Connected__Item--primary">
          <div class="Polaris-TextField" :class=" invalid ? 'Polaris-TextField--error' : '' ">
            <div class="Polaris-TextField__Prefix" v-html="prefix"></div>
            <input :id="id + 'input'" class="Polaris-TextField__Input" type="number" :value="value" :min="min" :max="max" :step="step"
              @input="$emit('input', $event.target.value)" :aria-labelledby="id + 'label'" aria-invalid="false"
              aria-multiline="false">

            <div class="Polaris-TextField__Spinner" aria-hidden="true">
              <div role="button" class="Polaris-TextField__Segment" tabindex="-1" @click="more()" >
                <div class="Polaris-TextField__SpinnerIcon">
                  <span class="Polaris-Icon">
                    <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                      <path d="M15 12l-5-5-5 5z"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div role="button" class="Polaris-TextField__Segment" tabindex="-1" @click="less()" >
                <div class="Polaris-TextField__SpinnerIcon">
                  <span class="Polaris-Icon">
                    <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                      <path d="M5 8l5 5 5-5z" fill-rule="evenodd"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div class="Polaris-TextField__Backdrop"></div>
          </div>
        </div>
      </div>
      <div class="Polaris-Labelled__Error">
        <div id="PolarisTextField6Error" class="Polaris-InlineError">
          <div class="Polaris-InlineError__Icon" v-show="invalid">
            <span class="Polaris-Icon">
              <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z" fill-rule="evenodd"></path>
              </svg>
            </span>
          </div>
          {{ invalid }}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    props: {
      value: {},
      label: {
        type: String,
      },
      prefix: {
        type: String,
      },
      invalid: {
        type: String,
        default: null,
      },
      min: {
        type: Number,
        required: true,
      },
      max: {
        type: Number,
        required: true,
      },
      step: {
        type: Number,
        required: true,
      },
    },
    computed: {
      id() {
        return this._uid;
      },
    },
    methods:{
      more(){
        this.$emit('input' ,  new Number(this.value)  +  new Number(this.step)  );
      },
      less(){
        this.$emit('input' ,  new Number(this.value)  -  new Number(this.step)  );
      },
    }
    
  }
</script>

<style>

</style>