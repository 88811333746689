<template>
  <div class=" ">
    <div class="Polaris-Page">
      <div class="Polaris-Page-Header">
        <div class="Polaris-Page-Header__MainContent">
          <div class="Polaris-Page-Header__TitleActionMenuWrapper">
            <div>
              <div class="Polaris-Header-Title__TitleAndSubtitleWrapper">
                <div class="Polaris-Header-Title">
                  <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">Settings</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="Polaris-Page__Content">
        <div class="Polaris-Card">
          <div class="Polaris-Card__Section">
            <div class="Polaris-SettingAction">
              <div class="Polaris-SettingAction__Setting">
                <p>
                  Store status(<span :class="storeInfo.active ? 'text-success' : 'text-warning' "
                    v-text=" `${ storeInfo.active ? ' Online ' : ' Offline ' }`"></span>)
                </p>
              </div>
              <div class="Polaris-SettingAction__Action">
                <div class="Polaris-ButtonGroup Polaris-ButtonGroup--segmented" data-buttongroup-segmented="true">
                  <div class="Polaris-ButtonGroup__Item">
                    <button type="button" class="Polaris-Button Polaris-Button--sizeSlim"
                      :class=" !storeInfo.active ? 'Polaris-Button--pressed ':'' " @click="fnChangeStoreStatus(false)"
                      aria-pressed="true">
                      <span class="Polaris-Button__Content">
                        <span class="Polaris-Button__Text">Offline</span>
                      </span>
                    </button>
                  </div>
                  <div class="Polaris-ButtonGroup__Item">
                    <button type="button" class="Polaris-Button Polaris-Button--sizeSlim"
                      @click="fnChangeStoreStatus(true)" :class=" storeInfo.active ? 'Polaris-Button--pressed ':'' "
                      aria-pressed="false"><span class="Polaris-Button__Content">
                        <span class="Polaris-Button__Text">
                          Online</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div> -->
      <div class="Polaris-Page__Content">
        <div class="Polaris-Card">
          <v-observer class=" " tag="form" ref="formInformation" @submit.prevent="fnValidateInfo()">

            <div class="Polaris-Card__Section px-0">
              <div class="Polaris-Card__Header">
                <h3 class="Polaris-Heading" v-text=" 'Account information' "> </h3>
              </div>
              <div class="Polaris-Card__Section">
                <div class="Polaris-FormLayout">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'User name' ">
                        <PolarisInputText v-model="user.first_name " :invalid="errors[0]" :label=" 'User name' " />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-6">
                      <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'User last name' ">
                        <PolarisInputText v-model="user.last_name " :invalid=" errors[0] "
                          :label=" 'User last name' " />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-6">
                      <v-validation rules="required|min:10" v-slot="{ errors }" :name=" 'User phone' ">
                        <PolarisInputText v-model="user.phone" :invalid="errors[0]" :label=" 'User phone' "
                          type="text" />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-6">
                      <v-validation rules="required" v-slot="{ errors }" :name=" 'User country' ">
                        <PolarisSelect v-model="user.country" :invalid=" errors[0] " :label=" 'User country' "
                          :options=" countryListShopify " />
                      </v-validation>
                    </div>
                    <!-- <div class="col-12 col-md-6">
                      <v-validation rules="required" v-slot="{ errors }" :name=" 'User language' ">
                        <PolarisSelect v-model="user.language" :invalid=" errors[0] " :label=" 'User language' "
                          :options=" languageListShopify " />
                      </v-validation>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="Polaris-Card__Section px-0">
              <div class="Polaris-Card__Header">
                <h3 class="Polaris-Heading" v-text=" 'Store information' "> </h3>
              </div>
              <div class="Polaris-Card__Section">
                <div class="Polaris-FormLayout">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'Store name' ">
                        <PolarisInputText v-model="storeInfo.name " :invalid=" errors[0] " :label=" 'Store name' " />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-6">
                      <v-validation rules="required|email" v-slot="{ errors }" :name=" 'Store email' ">
                        <PolarisInputText v-model="storeInfo.email " type="email" :invalid=" errors[0] "
                          :label=" 'Store email' " />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-6">
                      <v-validation rules="required|min:10|max:18" v-slot="{ errors }" :name=" 'Store phone' ">
                        <PolarisInputText v-model="storeInfo.phone " :invalid=" errors[0] " :label=" 'Store phone' " />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-6">
                      <v-validation rules="required|min_value:8.5|max_value:100" v-slot="{ errors }"
                        :name=" 'Ecart commission'  ">
                        <PolarisInputNumber v-model="storeInfo.settings.commission" :invalid=" errors[0]" :min="8.5"
                          :max="100" :step="0.5" :label=" 'Ecart commission ( minumum 8.5 % )' " type="number" />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-6">
                      <v-validation rules="required" v-slot="{ errors }" :name=" 'Products Language' ">
                        <PolarisSelect v-model="storeInfo.settings.language" :invalid=" errors[0] "
                          :label=" 'Products Language' " :options=" languageList " placeholder="Select" />

                      </v-validation>
                    </div>
                    <div class="col-12 col-md-6">
                      <v-validation rules="required" v-slot="{ errors }" :name=" 'Store currency' ">
                        <PolarisSelect v-model="storeInfo.currency" :invalid=" errors[0] " :label=" 'Store currency' "
                          :options=" currencyList " placeholder="Select" />
                      </v-validation>
                    </div>
                    <div class="col-12">
                      <v-validation rules="required|min:8" v-slot="{ errors }" :name=" 'Store description' ">
                        <PolarisTextField1 :label=" 'Store description' " :invalid=" errors[0] "
                          v-model="storeInfo.description" />
                      </v-validation>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="Polaris-Card__Section px-0">
              <div class="Polaris-Card__Header">
                <h3 class="Polaris-Heading" v-text=" 'Warehouse address' "> </h3>
              </div>
              <div class="Polaris-Card__Section">
                <div class="Polaris-FormLayout">
                  <div class="row">
                    <div class="col-12">
                      <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'Warehouse name' ">
                        <PolarisInputText v-model="storeAddress.address_name " :invalid=" errors[0] "
                          :label=" 'Warehouse name' " />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-6">
                      <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'Manager name' ">
                        <PolarisInputText v-model="storeAddress.first_name " :invalid=" errors[0] "
                          :label=" 'Manager name' " />
                      </v-validation>

                    </div>
                    <div class="col-12 col-md-6">
                      <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'Manager last name' ">
                        <PolarisInputText v-model="storeAddress.last_name " :invalid=" errors[0] "
                          :label=" 'Manager last name' " />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-6">
                      <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'Address 1'  ">
                        <PolarisInputText v-model="storeAddress.address1" :invalid=" errors[0] "
                          :label=" 'Address 1' " />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-6">
                      <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'Address 2' ">
                        <PolarisInputText v-model="storeAddress.address2" :invalid=" errors[0] "
                          :label=" 'Address 2' " />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-6">
                      <v-validation rules="required" v-slot="{ errors }" :name=" 'Country'  ">
                        <PolarisSelect v-model="storeAddress.country.code" :invalid=" errors[0] " :label=" 'Country' "
                          @change=" fnGetStates( storeAddress.country.code , true ) " :options=" countryListShopify "
                          placeholder="Select" />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-6">
                      <div>
                        <v-validation :rules=" statleListShopify.length > 0 ? 'required' : ''" v-slot="{ errors }"
                          :name=" 'State' " v-show=" statleListShopify.length > 0 ">
                          <PolarisSelect v-model="storeAddress.state.code" :invalid=" errors[0] " :label=" 'State' "
                            :options=" statleListShopify " placeholder="Select" />
                        </v-validation>

                        <v-validation :rules=" statlesList.length == 0 ? 'required' : ''" v-slot="{ errors }"
                          :name=" 'State' " v-show=" statlesList.length == 0 ">
                          <PolarisInputText v-model="storeAddress.state.name" :invalid=" errors[0] "
                            :label=" 'State' " />
                        </v-validation>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'City' ">
                        <PolarisInputText v-model="storeAddress.city" :invalid=" errors[0] " :label=" 'City' " />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-4">
                      <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'Zip code' ">
                        <PolarisInputText v-model="storeAddress.postal_code" :invalid=" errors[0] "
                          :label=" 'Zip code' " />
                      </v-validation>
                    </div>
                    <div class="col-12 col-md-4">
                      <v-validation rules="required|min:10|max:18" v-slot="{ errors }" :name=" 'Phone' ">
                        <PolarisInputText v-model="storeAddress.phone" :invalid=" errors[0] " :label=" 'Phone' " />
                      </v-validation>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="Polaris-Card__Section px-0">
              <div class="Polaris-Card__Section py-0">
                <div class="Polaris-SettingAction">
                  <div class="Polaris-SettingAction__Setting">
                    <p v-if="invalid" class="text-danger">
                      The information is not correct,<span class="Polaris-TextStyle--variationStrong">
                        please verify</span>.
                    </p>
                  </div>

                  <div class="Polaris-SettingAction__Action">
                    <button type="button" class="Polaris-Button Polaris-Button--primary"
                      :class="sendingData ? 'Polaris-Button--disabled Polaris-Button--loading':'' "
                      :disabled="sendingData" @click="fnValidateInfo()">
                      <span class="Polaris-Button__Content">
                        <span class="Polaris-Button__Spinner" v-show="sendingData">
                          <span
                            class="Polaris-Spinner Polaris-Spinner--colorInkLightest Polaris-Spinner--sizeSmall"><svg
                              viewBox="0 0 20 20">
                              <path
                                d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z">
                              </path>
                            </svg>
                          </span>
                          <span role="status">
                            <span class="Polaris-VisuallyHidden" v-text="'Save information'"></span>
                          </span>
                        </span>
                        <span class="Polaris-Button__Text" v-text="'Save information'">
                        </span>
                      </span>
                    </button>

                  </div>
                </div>
              </div>
            </div>

          </v-observer>
        </div>

        <div class="Polaris-Card d-none">
          <div class="Polaris-Card__Header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="Polaris-Heading" v-text=" 'Payments information' "> </h3>
              </div>
              <div class="col-auto">
                <button type="button" @click=" fnAddWew() "
                  class="Polaris-Button Polaris-Button--primary Polaris-Button--sizeSlim">
                  <span class="Polaris-Button__Content">
                    <span class="Polaris-Button__Text">Add new</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="Polaris-Card__Section">
            <p>These payment methods will help us pay you.</p>
          </div>

          <div class="Polaris-Card__Section" v-for=" (method,index) in methodList" :key="index">
            <div class="row justify-content-between align-items-center ">
              <div class="col-8">
                <p> <span v-text=" 'Method: ' + method.method "></span> </p>
                <p v-if="method.name"> <span v-text=" 'Alias: ' + method.name "></span> </p>

              </div>
              <div class="col-auto ">
                <div>
                  <div class="Polaris-ButtonGroup">
                    <div class="Polaris-ButtonGroup__Item">
                      <button type="button" @click="fnDelteMethod(index)"
                        class="Polaris-Button Polaris-Button--destructive  Polaris-Button--outline Polaris-Button--sizeSlim ">
                        <span class="Polaris-Button__Content">
                          <span class="Polaris-Icon">
                            <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                              <path
                                d="M16 6H4a1 1 0 1 0 0 2h1v9a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V8h1a1 1 0 1 0 0-2zM9 4a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2H9zm2 12h2V8h-2v8zm-4 0h2V8H7v8z"
                                fill-rule="evenodd"></path>
                            </svg>
                          </span>

                        </span>
                      </button>
                    </div>
                    <div class="Polaris-ButtonGroup__Item">
                      <button type="button" @click=" editMethod(index)" class="Polaris-Button Polaris-Button--sizeSlim">
                        <span class="Polaris-Button__Content">
                          <span class="Polaris-Button__Text">Edit</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <pre>  {{methodList}} </pre> -->

        </div>



      </div>

    </div>


    <PolarisModal :title="newMethod.id ? 'Update method' : 'Add new method' " :visible="modalPaymentMethod"
      @close-modal="resetPaymentMethod()" :btnOkMessage=" newMethod.id ? 'Update' : 'Add' "
      @action-modal="fnAddNewPayment()" :loading="loading.sendingNewMethod">
      <v-observer tag="form" @submit.prevent="fnAddNewPayment()" ref="methodForm">
        <div class="Polaris-FormLayout">
          <div class="row">
            <div class="col-12 col-md-6">
              <v-validation v-slot="{ errors }" :name=" 'Payment type' ">
                <PolarisSelect v-model="newMethod.method" :invalid=" errors[0] " :label=" 'Payment type' "
                  :disabled=" newMethod.id ? true : false " :options=" paymentMethodsShopify " />
              </v-validation>
            </div>
            <div class="col-12 col-md-6">
              <v-validation rules="min:3" v-slot="{ errors }" :name="  'Payment alias'  ">
                <PolarisInputText v-model="newMethod.name " :invalid=" errors[0] " :label=" 'Payment alias' " />
              </v-validation>
            </div>
            <!-- Methods -->
            <div class="col-12" v-show=" newMethod.method == 'paypal'">
              <v-validation :rules=" newMethod.method == 'paypal' ? 'required|email' : '' " v-slot="{ errors }"
                :name=" 'Paypal email' ">
                <PolarisInputText v-model="newMethod.email" v-bind:invalid=" errors[0] " :label=" 'Paypal email' " />
              </v-validation>
            </div>
            <div class="col-12" v-show="newMethod.method == 'debit'">
              <div class="row">
                <div class="col-12 col-md-6">
                  <v-validation :rules=" newMethod.method == 'debit' ? 'required|min:16|max:16' : ''"
                    v-slot="{ errors }" :name=" 'Card number' ">
                    <PolarisInputText v-model="newMethod.number" :invalid=" errors[0] " :label=" 'Card number' " />
                  </v-validation>
                </div>
                <div class="col-12 col-md-6">
                  <v-validation :rules=" newMethod.method == 'debit' ? 'required' : ''" v-slot="{ errors }"
                    :name=" 'Bank' ">
                    <PolarisSelect v-model="newMethod.bank" :invalid=" errors[0] " :label=" 'Bank' "
                      :options=" bankListShopify " />
                  </v-validation>
                </div>
              </div>
            </div>
            <div class="col-12" v-show=" newMethod.method == 'clabe'">
              <div class="row">
                <div class="col-12 col-md-6">
                  <v-validation :rules=" newMethod.method == 'clabe' ? 'required|min:18|max:18' : ''"
                    v-slot="{ errors }" :name="  'Clabe'  ">
                    <PolarisInputText v-model="newMethod.clabe " :invalid=" errors[0] " :label=" 'Clabe' " />
                  </v-validation>
                </div>
                <div class="col-12 col-md-6">
                  <v-validation :rules=" newMethod.method == 'clabe' ? 'required|min:3' : ''" v-slot="{ errors }"
                    :name=" 'Business name' ">
                    <PolarisInputText v-model="newMethod.business_name " :invalid=" errors[0] "
                      :label=" 'Business name' " />
                  </v-validation>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input type="submit" hidden>
      </v-observer>

    </PolarisModal>

  </div>
</template>

<script>
  import '@shopify/polaris/styles.css';
  import PolarisInputText from '@/components/shopify/polaris-input-text';
  import PolarisCheckbox from '@/components/shopify/polaris-checkbox';
  import PolarisSelect from '@/components/shopify/polaris-select';
  import PolarisInputNumber from '@/components/shopify/polaris-input-number';
  import PolarisTextField1 from '@/components/shopify/polaris-text-field';
  import PolarisModal from '@/components/shopify/polaris-modal';

  import _default, {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";

  export default {
    components: {
      PolarisInputText,
      PolarisCheckbox,
      PolarisInputNumber,
      PolarisSelect,
      PolarisTextField1,
      PolarisModal,
    },
    data() {
      return ({
        invalid: false,
        modalNewMethod: false,
        // loading: {
        //   newMethod: false,
        // },
        sendingData: false,
        user: {
          first_name: null,
          last_name: null,
          phone: null,
          country: null,
        },
        paymentMethod: ['card'],
        countryList: [],
        statlesList: [],
        statleListShopify: [],
        countryListShopify: [],
        currencyList: [],
        languageList: [{
            label: 'Español',
            value: 'es-ES',
          },
          {
            label: 'English',
            value: 'en-US',
          },
        ],
        addresses: [],
        storeInfo: {
          active: false,
          name: ' ',
          phone: null,
          email: null,
          description: ' ',
          image: null,
          image_url: null,
          settings: {
            commission: null,
            language: null,
          },
          currency: null,
        },
        storeAddress: {
          address_name: null,
          first_name: null,
          last_name: null,
          address1: null,
          address2: null,
          country: {
            code: null,
          },
          state: {
            code: null,
            name: null,
          },
          city: null,
          postal_code: null,
          phone: null,
        },
        bankListShopify: [],
        paymentMethodsShopify: [],
        // Store Image
        maxSizeImageAlert: false,
      })
    },
    computed: {
      ...mapState({
        loading: state => state.payments.loading,
        methodList: state => state.payments.methodList,
        modalPaymentMethod: state => state.payments.modalPaymentMethod,
        paymentMethods: state => state.payments.paymentMethods,
        newMethod: state => state.payments.newMethod,
      }),
    },
    methods: {
      ...mapActions('ShopifySession', ['fnLogout']),
      ...mapMutations('ShopifyPayments', ['changeModal', 'resetPaymentMethod', 'editMethod']),
      ...mapActions('ShopifyPayments', ['fnApiGetMthods', 'fnApiAddNewMethod']),

      async fnValidateInfo() {
        this.sendingData = true;

        if (await this.$refs['formInformation'].validate()) {
          this.invalid = false;
          if (await this.fnApiUpdateUserInfo()) {
            if (await this.fnApiUpdateStore()) {
              if (await this.fnApiAddStoreAddress()) {
                this.$toasted.global.infoMessage('Account information updated successfully');
                this.fnApiCompleteUser();
              }
            }
          }
        } else {
          this.invalid = true;
        }
        this.sendingData = false;
      },


      // --------- UPDATES ---------
      // USER INFO AND PAYMENT METHOD
      async fnApiUpdateUserInfo() {
        return await axiosShopify.put('me', this.user).then(async response => {
          return true;
        }).catch(error => {
          return false;
        });
      },
      // STORE INFO
      async fnApiUpdateStore() {
        let {
          name,
          email,
          description,
          phone,
          settings,
          image,
          currency
        } = this.storeInfo;
        let tempStoreInfo = {
          complete: true
        };
        if (name != null) {
          tempStoreInfo.name = name
        };
        if (email != null) {
          tempStoreInfo.email = email
        };
        if (description != null) {
          tempStoreInfo.description = description
        };
        if (phone != null) {
          tempStoreInfo.phone = phone
        };
        if (settings != null) {
          tempStoreInfo.settings = settings
        };
        if (image != null) {
          tempStoreInfo.image = image
        };
        if (currency != null) {
          tempStoreInfo.currency = currency
        };
        return await axiosShopify.put(`stores/${this.storeId}`, tempStoreInfo).then((response) => {
          return true;
        }).catch(error => {
          return false;
        });
      },
      // ADDRESS INFO
      async fnApiAddStoreAddress() {
        let tempAddress = {
          address_name: this.storeAddress.address_name,
          first_name: this.storeAddress.first_name,
          last_name: this.storeAddress.last_name,
          address1: this.storeAddress.address1,
          address2: this.storeAddress.address2,
          country: {
            code: this.storeAddress.country.code
          },
          city: this.storeAddress.city,
          postal_code: this.storeAddress.postal_code,
          phone: this.storeAddress.phone,
          state: {},
        };
        if (this.statlesList.length > 0) {
          tempAddress.state.code = this.storeAddress.state.code;
        } else {
          tempAddress.state.name = this.storeAddress.state.name;
        }
        if (this.storeAddress.id != null) {
          let tempId = this.storeAddress.id;
          return await axiosShopify.put(`stores/${this.storeId}/addresses/${ this.storeAddress.id }`,
            tempAddress).then((response) => {
            return true;
          }).catch(error => {
            return false;
          });
        } else {
          return await axiosShopify.post(`stores/${this.storeId}/addresses`, tempAddress).then((response) => {
            this.storeAddress.id = response.data.id;
            return true;
          }).catch(error => {
            return false;
          });
        }
      },
      // GET INFO
      fnApiGetUserInfo() {
        axiosShopify.get('me', this.user).then(response => {
          this.user.first_name = response.data.first_name;
          this.user.last_name = response.data.last_name;
          this.user.country = response.data.country || null;
          this.user.phone = response.data.phone || null;
        }).catch(error => {});
      },
      async fnSetLists() {
        this.countryList = await this.fnApiGetCountries();
        this.countryListShopify = [];
        for (let country of this.countryList) {
          this.countryListShopify.push({
            label: country.name,
            value: country.code,
          })
        }
        for (let currency of this.CONST.CURRENCIES) {
          this.currencyList.push({
            label: currency,
            value: currency,
          })
        }

        for (let bank of this.CONST.BANKS) {
          this.bankListShopify.push({
            label: bank,
            value: bank,
          })
        }
        for (let method of this.paymentMethods) {
          this.paymentMethodsShopify.push({
            label: method.toUpperCase(),
            value: method,
          })
        }
      },
      async fnGetStates(code, countryChanged) {
        this.statlesList = [];
        this.statleListShopify = [];
        this.statlesList = await this.fnApiGetStates(code);

        if (this.statlesList.length > 0) {
          for (let state of this.statlesList) {
            this.statleListShopify.push({
              label: state.name,
              value: state.code_2_digits ? state.code_2_digits : state.code_3_digits,
            })
          }
        }

        if (countryChanged) {
          this.storeAddress.state.code = null;
          this.storeAddress.state.name = null;
          if (this.statlesList.length > 0) {
            this.storeAddress.state.code = this.statlesList[0].code_2_digits;
          }
        }

      },
      async fnApiUpdateShopifyStore() {
        await axiosShopify.put(`stores/${this.storeId}/seller`).then(async (response) => {
          this.storeInfo.image = response.data.image || null;
          this.storeInfo.currency = response.data.currency || null;
          this.storeInfo.active = response.data.active || null;
          this.storeInfo.id = response.data.id || null;
          this.storeInfo.ecommerce = response.data.ecommerce || null;
          this.storeInfo.url = response.data.url || null;
          this.storeInfo.name = response.data.name || null;
          this.storeInfo.email = response.data.email || null;
          this.storeInfo.phone = response.data.phone || null;
          this.storeInfo.description = response.data.description || null;
          this.storeInfo.image_url = response.data.image_url || null;
          if (response.data.settings) {
            this.storeInfo.settings = {
              commission: response.data.settings.commission,
              language: (response.data.settings.language) || null,
            };

            switch (this.storeInfo.settings.language) {
              case 'es':
                this.storeInfo.settings.language = 'es-ES';
                break;
              case 'en':
                this.storeInfo.settings.language = 'en-US';
                break;
            }
          } else {
            this.storeInfo.settings = {
              commission: null,
              language: null,
            }
          }
          // Address
          this.storeInfo.addresses = response.data.addresses;
          this.addresses = this.storeInfo.addresses;
          if (this.addresses.length > 0) {
            let tempAddress = this.addresses[0];
            await this.fnGetStates(tempAddress.country.code);
            this.storeAddress = {
              id: tempAddress.id,
              address_name: tempAddress.address_name,
              first_name: tempAddress.first_name,
              last_name: tempAddress.last_name,
              address1: tempAddress.address1,
              address2: tempAddress.address2,
              country: {
                code: tempAddress.country.code
              },
              city: tempAddress.city,
              postal_code: tempAddress.postal_code,
              phone: tempAddress.phone,
              state: {},
            };
            if (tempAddress.state.code) {
              this.storeAddress.state.code = tempAddress.state.code;
            } else {
              this.storeAddress.state.name = tempAddress.state.name;
            }
          }
        }).catch(error => {
          // Redirect to logout
          this.fnLogout('/shopify');
        });
      },
      // Store Image
      fnChangeStoreImage() {
        document.getElementById('storeImage').click();
      },
      fnStoreImage(event) {
        this.maxSizeImageAlert = false;
        var file = event.target.files[0];
        if (file != undefined) {
          if (file.size < (2 * 1000 * 1000)) {
            var reader = new FileReader();
            var baseString;
            reader.onloadend = ((event) => {
              this.storeInfo.image = event.target.result;
            });
            reader.readAsDataURL(file);
          } else {
            this.maxSizeImageAlert = true;
          }
        }
      },

      // Payments
      fnDelteMethod(index) {
        axiosShopify.delete(`me/payments/method/${this.methodList[index].id}`).then(response => {
          this.methodList.splice(index, 1);
        }).catch(error => {});
      },

      fnAddWew() {
        this.changeModal(true);
        this.$refs['methodForm'].reset();
      },

      async fnAddNewPayment() {
        if (await this.$refs['methodForm'].validate()) {
          await this.fnApiAddNewMethod(this.newMethod);
        }
      },

      async fnApiCompleteUser() {
        await axiosShopify.put('me/complete').then(async response => {
          localStorage.setItem('user', JSON.stringify(response.data))
          this.$store.commit('session/setSession', response.data);
        }).catch(error => {});
      }
    },

    async created() {
      // await this.fnApiGetMthods();
      await this.fnSetLists();
      await this.fnApiGetUserInfo();
      if (this.$route.query.store_id) {
        this.storeId = this.$route.query.store_id;
        await this.fnApiUpdateShopifyStore();
      }
    }

  }
</script>